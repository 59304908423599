import React from "react"
import styled from "styled-components"
import Layout from "../../components/Layout"
import SEO from "../../components/Seo"

import Container from "~layout/Container"

const Wrapper = styled.div`
  margin: 80px 0px;
`
const NewsImg = styled.img`
  position: relative;
  object-fit: cover;
  object-position: center;
  display: block;
  height: 321px;
  width: 100%;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.26);
`
const NewsTitle = styled.div`
  color: #003d6a;
  font-family: "Palanquin";
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;
`
const NewsLocation = styled.div`
  color: #003d6a;
  font-family: "Palanquin";
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
`
const NewsInfoFlex = styled.div`
  display: flex;
  margin-bottom: 19px;
`
const NewsExcerpt = styled.div`
  color: #003d6a;
  font-family: "Palanquin";
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  margin-bottom: 40px;
`
const News = ({ pageContext }) => {
  const {
    post: { title, content, featuredImage, newsItem },
  } = pageContext
  const { date, city, country } = newsItem
  return (
    <Layout>
      <SEO title={title} />
      <Container overflowDisabled>
        <Wrapper>
          <NewsImg
            src={featuredImage?.node?.sourceUrl}
            alt={featuredImage?.node?.altText}
          />
          <NewsTitle>{title}</NewsTitle>
          <NewsInfoFlex>
            <NewsLocation>
              {city}
              {city !== "" && country !== "" ? ", " : null}
              {country}
            </NewsLocation>
          </NewsInfoFlex>
          <NewsExcerpt dangerouslySetInnerHTML={{ __html: content }} />
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default News
